const esTranslations = {

	/**
	  * Global
	  */
	enviar: "Enviar",
	cancelar: "Cancelar",
	continuar: "Continuar",
	guardar: "Guardar",
	volver: "Volver",
	editar: "Editar",
	cargando: "Cargando ...",
	nuevo: "Nuevo",

	/**
	  * Nav
	  * Shop/components/Nav y FilterNav
	  */
	notProducts: "Sin Productos aún",
	search: "Buscar",
	myShopping: "Mis Compras",
	hello: "Hola",
	messages: "Mensajes",
	inquieryBasket: "Carrito",
	products: "Productos",
	aboutUs: "Acerca de nosotros",
	homePage: "Inicio",
	sign_in: "Iniciar Sesión",
	join: "Registrarse",
	logout: "Cerrar Sesión",
	//FilterNav
	filtros: "Filtros",
	cerrar: "Cerrar",
	categorias: "Categorías",
	departamentos: "Departamentos",
	ordenar_por: "Ordenar por",
	precio_bajo_alto: "Precio bajo-alto",
	precio_alto_bajo: "Precio alto-bajo",
	mas_nuevo: "Más nuevo",

	/**
	  * Home
	  * Shop/components/Home
	  */
	bestSeller: "Lo más vendido",
	new: "Nuevo",
	recentlyView: "Vistos Recientes",

	/**
	  * Footer
	  * Shop/components/Footer y Footer2
	  */
	nombre: "Nombre",
	correo: "Correo",
	mensaje: "Mensaje",
	servicioCliente: "Servicio al cliente",
	envianosCorreo: "Envianos un correo",
	uneteAFamilia: "Únete a la familia  para recibir noticias y novedades de nuestros productos",
	quienesSomos: "¿Quiénes somos?",
	avisoPrivacidad: "Aviso de privacidad",
	trabajaConNosotros: "Trabaja con nosotros",
	paraClientes: "Para Clientes",
	pagosEnvios: "Pagos y envíos",
	politicasCompra: "Políticas de compra",
	redesSociales: "Redes Sociales",
	todosLosDerechosReservados: "Todos los derechos reservados",

	/**
	  * Banner2.js
	  * Shop/components/Home/BAnner2.js
	  */
	enero: "Enero",
	febrero: "Febrero",
	marzo: "Marzo",
	abril: "Abril",
	mayo: "Mayo",
	junio: "Junio",
	julio: "Julio",
	agosto: "Agosto",
	septiempre: "Septiempre",
	octubre: "Octubre",
	noviembre: "Noviembre",
	diciembre: "Diciembre",
	increible_con_ofertas_increibles: "increíble con ofertas increíbles",
	multiples_soluciones_demandas_especiales: "Múltiples soluciones para tus demandas especiales",
	categorias: "Categorías",
	servicios: "Servicios",
	proveedores_lideres: "Proveedores Líderes",
	centro: "CENTRO",

	/**
	  * Modals
	  * Shop/components/Modals
	  */
	//ModalAdress.js
	agregar_direccion: "Agregar dirección",
	direccion: "Dirección",
	nombre_completo: "Nombre Completo",
	numero_exterior: "Número exterior",
	numero_interior: "Número interior",
	cp: "Código postal",
	estado: "Estado",
	provincia: "Provincia",
	ciudad: "Ciudad",
	selecciona_colonia: "Selecciona tu colonia",
	colonia: "Colonia",
	instrucciones_entrega: "Instrucciones de entrega",
	telefono: "Teléfono",
	guardar: "Guardar",
	//ModalContactNow.js
	mensaje: "Mensaje",
	mensaje_enviado: "Mensaje enviado",
	enviar: "Enviar",
	mensaje_para: "Mensaje para",
	//ModalCotizar.js
	cotizacion_enviada: "Cotización Enviada",
	destino_de_la_mercancia: "Destino de la mercancía",
	elige_pais: "Elige tu país",
	pais: "País",
	elige_ciudad: "Elige tu ciudad",
	ciudad: "Ciudad",
	selecciona_servicios_cotizar: "Selecciona servicios a cotizar",
	si: "Si",
	no: "No",
	cargo_internacional: "Cargo Internacional",
	seguro: "Seguro",
	despacho_aduana: "Despacho de aduana",
	entrega_en_destino: "Entrega en destino",
	asesoria: "Asesoría",

	/**
	  * ProductCard
	  * Shop/components/ProductCard
	  */
	precio: "Precio",
	pieza: "Pieza",
	orden: "Orden",
	piezas: "Piezas",

	/**
	  * Proximamente
	  * Shop/components/Proximamente
	  */
	en_construccion: "En Construcción",
	vuelve_mas_tarde: "Vuelve más tarde para disfrutar de todo lo que tenemos para ti",

	/**
	  * 404
	  * Shop/pages/404/index.js
	  */
	lo_sentimos: "LO SENTIMOS",
	no_pudimos_encontrar_pagina: "no pudimos encontrar la página que buscabas",
	intenta_usar_barra_busqueda: "Intenta usar la barra de búsqueda o visita la",
	pagina_principal: "Página Principal",

	/**
	  * /aboutus
	  */
	//Shop/pages/aboutus/Components/Home/index.js y Shop/pages/proveedor/Components/Home/index.js
	mensaje_enviado: "Mensaje enviado",
	revisa_mensajes_en_panel: "Revisa tus mensajes en el panel",
	aboutUs: "Acerca de nosotros",
	perfil_de_la_empresa: "Perfil de la empresa",
	capacidad_comercial: "Capacidad Comercial",
	capacidad_de_produccion: "Capacidad de producción",
	ver_empresa: "Ver empresa",
	contactar_al_proveedor: "Contactar al proveedor",
	chatea_con_el_proveedor: "Chatea con el proveedor",
	tipo_negocio: "Tipo de negocio",
	productos_principales: "Productos principales",
	area_de_planta: "Área de planta",
	certificacion_del_sistema: "Certificación del sistema de gestión",
	terminos_de_entrega: "Términos de entrega",
	tipo_de_pago: "Tipo de pago",
	porcentaje_de_exportacion: "Porcentaje de exportación",
	mercados_principales: "Mercados principales",
	puerto_cercano: "Puerto cercano",
	numero_de_licencia_de_exportacion: "Número de licencia de exportación",
	archivo_de_licencia_de_exportacion: "Archivo de la licencia de exportación",
	direccion_operativa_de_la_empresa: "Dirección operativa de la empresa",
	total_de_empleados: "Total de empleados",
	tamaño_de_oficina: "Tamaño de la oficina",
	produccion_anual_de_productos_principales: "Producción anual de los productos principales",
	por_encima: "Por encima",
	album_de_la_empresa: "Álbum de la empresa",
	verificado: "Verificado",
	certificados: "Certificados",
	elementos: "Elementos",

	/**
	  * /account_suspended
	  */
	//Shop/pages/account_suspended/index.js
	cuenta_suspendida: "Cuenta Suspendida",

	/**
	  * /bolsa_trabajo
	  */
	//Shop/pages/bolsa_trabajo/Components/Bolsa/index.js
	si_consideras_formar_parte_equipo: "Si consideras que puedes formar parte del equipo, te invitamos a que nos compartas tus datos",
	nivel_estudios: "Nivel de estudios",
	puesto_solicitado: "Puesto Solicitado",
	nota: "Nota",

	/**
	  * /cat
	  */
	//Shop/pages/cat/Components/Categoria/index.js
	grupos_de_productos: "Grupos de productos",

	/**
	  * /faq
	  */
	//Shop/pages/faq/Components/FAQ/index.js
	preguntas_frecuentes: "Preguntas Frecuentes",

	/**
	  * /login
	  */
	//Shop/pages/login/Components/Login/index.js
	credenciales_no_coinciden: "Las credenciales no coinciden con nuestras bases de datos intentelo más tarde",
	crea_una_cuenta_y_disfruta: "Crea una cuenta y disfruta de los Beneficios de",
	contraseña: "Contraseña",
	entrar: "Entrar",
	registrate_aqui: "Registrate aquí",
	olvide_mi_contraseña: "Olvidé mi contraseña",
	no_tienes_cuenta: "¿No tienes una cuenta?",

	/**
	  * /messages
	  */
	//Shop/pages/messages/Components/Messages/index.js
	miembro_diamante: "Miembro Diamante",
	desde: "Desde",
	proveedor_auditado: "Proveedor auditado",
	visto: "visto",
	enviado: "enviado",
	recibido: "recibido",

	/**
	  * /micarrito
	  */
	//Shop/pages/micarrito/Components/Carrito/index.js
	algunos_articulos_en_carrito_estan_agotados: "Algunos artículos en tu carrito están agotados",
	proceder_al_pago: "Proceder al pago",
	tu_carrito_esta_vacio: "Tu carrito esta vacío",
	sin_atributo: "Sin atributo",
	sin_descripcion: "Sin descripcion",
	disponible: "Disponible",
	stock: "Existencias",
	comprar: "Comprar",
	eliminar: "Eliminar",
	guardar_mas_tarde: "Guardar para más tarde",
	comprar_articulos_seleccionados: "Comprar articulos seleccionados",
	//Shop/pages/micarrito/Components/StattusBar/index.js
	CARRITO: "CARRITO",
	DIRECCION: "DIRECCIÓN",
	OPCIONES_RECOLECCION: "OPCIONES RECOLECCIÓN",
	PAGO: "PAGO",
	FINALIZAR: "FINALIZAR",
	/*
	   *
	   * /micarrito/direccion
	   */
	//Shop/pages/micarrito/direccion/Components/Direccion/index.js
	direccion_eliminada: "Dirección eliminada",
	telefono_incorrecto: "Teléfono incorrecto",
	no_dejes_pasar_el_momento: "No dejes pasar este momento",
	registrate: "Regístrate",
	HOLA: "HOLA",
	recolectar: "Recolectar",
	direccion_recoleccion: "Dirección de recolección",
	direccion: "Dirección",
	detalles_quien_recoge: "Detalles de quién recoge el paquete",
	elige_direccion_envio: "Elige tu dirección de envío",
	recoger_en_sucursal: "¿Recoger en sucursal?",
	añadir_direccion: "Añadir dirección",
	enviar_paquete_a: "Enviar paquete a",
	//Shop/pages/micarrito/direccion/Components/Direccion/new.js
	calle: "Calle",
	opcional: "Opcional",
	instrucciones_entrega: "Instrucciones de entrega",
	/*
	   *
	   * /micarrito/envio
	   */
	//Shop/pages/micarrito/envio/Components/Envio/index.js
	recogelo_tu_mismo: "Recógelo tú mismo",
	ver_mapa: "Ver mapa",
	horario_de_atencion: "Horario de atención",
	hora: "Hora",
	dias: "Días",
	economico: "Economico",
	paquete: "Paquete",
	elija_una_opcion: "Elija una opción",
	/*
	   *
	   * /micarrito/finaliza
	   */
	//Shop/pages/micarrito/finaliza/Components/Finalizar/index.js
	fecha_desconocida_consulte_su_guia: "Fecha desconocida consulte su guía en fedex.com",
	gracias_por_tu_pedido: "Gracias por tu pedido",
	tu_pedido_se_hizo_exitosamente: "Tu pedido se hizo exitosamente",
	fecha_entrega_estimada: "Fecha de entrega estimada",
	despues_de_validar_el_pago: "después de validar el pago",
	pedido: "Pedido",
	imprimir: "Imprimir",
	comprobante: "comprobante",
	imprime_o_muestra_este_comprobante: "imprime o muestra este comprobante en nuestra sucursal, junto con una identificación oficial para recoger tu pedido",
	articulos: "Articulos",
	codigo: "Código",
	cantidad: "Cantidad",
	descubre_mas_productos: "Descubre más productos",
	/*
	   *
	   * /micarrito/pago
	   */
	//Shop/pages/micarrito/pago/Components/Pago/index.js
	seleccione_articulos: "Seleccione Artículos",
	cupon_aplicado: "Cupón apicado",
	cupon_no_valido: "Cupón no válido",
	selecciona_un_metodo_pago: "Selecciona un metodo de pago",
	tarjeta_de_credito: "Tarjeta de credito",
	tarjeta_de_debito: "Tarjeta de debito",
	paga_en_oxxo: "Paga en oxxo",
	resumen: "Resumen",
	cupon: "Cupón",
	descuento_no_aplicable_a_gastos_envio: "El descuento no aplica a los costos de envío.",
	canjear_cupon: "Canjear cupón",
	pago_procesado_de_forma_segura: "Pago procesado de forma segura",
	problemas_con_tu_pago: "Problemas con tu pago?",
	verifica_que_tengas_fondos: "Verifica que tengas fondos en tu tarjeta",
	verifica_que_informacion: "Verifica que la información sea correcta",
	recuerda_activar_3d_en_tu_banco: "Recuerda que es importante activar 3D Secure con tu banco para poder realizar pagos en línea",
	proveedor_de_envio: "Proveedor de envío",
	fecha_entrega_estimada: "Fecha de entrega estimada",
	nombre_del_producto: "Nombre del producto",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/oxxoPay.js nota: oxxo solo esta disponible en mexico
	recuerda_que_tienes_3_dias_para_pagar_en_oxxo: "Recuerda que tienes 3 días a partir de hoy para realizar tu pago en OXXO",
	ficha_digital_no_es_necesario_imprimir: "Ficha digital, No es necesario imprimir",
	monto_pagar: "Monto a pagar",
	oxxo_cobra_comision: "OXXO cobrará una comisión adicional al momento de realizar el pago.",
	click_para_guardar: "Click para Guardar",
	REFERENCIA: "REFERENCIA",
	instrucciones: "Instrucciones",
	guardar_esta_ficha: "Guardar esta ficha",
	acude_a_oxxo: "Acude a la tienda OXXO más cercana",
	indica_en_caja_que_quieres_pagar_con_oxxopay: "Indica en caja que quieres realizar un pago de OXXOPay",
	muestra_al_cajero_referencia: "Muestra el al cajero el número de referencia",
	realiza_pago_correspondiente: "Realiza el pago correspondiente",
	conserva_comprobante: "Conserva tu comprobante de pago",
	encuentra_aqui: "Encuentra aquí",
	al_completar_pago_recibiras_correo_de_confirmacion: "Al completar estos pasos recibirás un correo de confirmación de",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/payPal.js
	paypal_locale: "es_MX",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/santander.js
	agregar_tarjeta: "Agregar tarjeta",

	/*
	   *
	   * /miperfil
	   */
	//Shop/pages/miperfil/index.js
	mi_cuenta: "Mi cuenta",
	apodo: "Apodo",
	mis_pedidos: "Mis pedidos",
	mis_direcciones: "Mis direcciones",
	mi_perfil: "Mi perfil",
	cerrar_sesion: "Cerrar Sesión",
	/*
	   *
	   * /miperfil/datos
	   */
	//Shop/pages/miperfil/datos/Components/MiPerfil/index.js
	tus_datos_se_actualizaron: "Tus datos se actualizaron correctamente",
	verifique_sus_datos: "Verifique sus datos",
	ingresa_nuevos_datos: "Ingresa los nuevos datos",
	confirmar_contraseña: "Confirmar contraseña",
	las_contraseñas_no_coinciden: "Las contraseñas no coinciden",
	/*
	   *
	   * /miperfil/misdirecciones
	   */
	//Shop/pages/miperfil/misdirecciones/Components/Direcciones/index.js
	direccion_agregada: "Dirección agregada",
	direccion_actualizada: "Dirección actualizada",
	direccion_eliminada: "Dirección eliminada",
	enviar_a: "Enviar a",

	/*
	   *
	   * /pedidos
	   */
	//Shop/pages/pedidos/Components/Pedidos/index.js
	carrito_actualizado: "Carrito actualizado",
	no_contamos_con_numero_de_productos_en_stock: "No contamos con ese número de productos en stock",
	pedidos_en_curso: "Pedidos en curso",
	pedidos: "Pedidos",
	cotizaciones: "Cotizaciones",
	no_se_encontraron_pedidos: "No se encontraron pedidos",
	llegando_el: "Llegando el",
	consulta_en_24_horas_progreso: "Consulta en 24 horas. El progreso",
	entrega_en_sucursal: "Entrega en sucursal",
	producto: "Producto",
	descripcion: "Descripción",
	pedido_el: "Pedido el",
	pagado: "Pagado",
	paquete_en_camino: "Paquete en camino",
	paquete_listo_para_recoger: "Paquete listo para recoger",
	paquete_entregado: "Paquete entregado",
	seguir_pedido: "Seguir pedido",
	recoger_en: "Recoger en",
	ver_detalles: "Ver detalles",
	evaluar_producto: "Evaluar producto",
	precio_del_envio: "Precio del envio",
	entregado: "Entregado",
	pendiente: "Pendiente",
	cotizacion: "Cotización",
	producto_cotizado: "Producto cotizado",
	proveedor: "Proveedor",
	archivo: "Archivo",

	/*
	   *
	   * /pedidos/pedido
	   */
	//Shop/pages/pedidos/pedido/Components/Pedido/index.js
	datos_de_envio: "Datos de envío",
	tipo_de_envio: "Tipo de envío",
	total: "Total",
	subtotal: "Subtotal",
	numero_de_guia: "Número de Guia",
	recoleccion: "Recolección",

	/*
	   *
	   * /product
	   */
	//Shop/pages/product/Components/Producto/index.js
	lo_sentimos_no_puedes_agregar_mas_productos: "Lo sentimos no puedes agregar mas productos de este tipo, haz superado el stock",
	lo_sentimos_no_hay_stock_disponible: "Lo sentimos, por el momento no hay stock disponible",
	compra_desde_mexico: "Compra desde México",
	producto_no_disponible: "Producto no disponible",
	numero_minimo_de_piezas: "Número mínimo de piezas",
	añadir_al_carrito: "Añadir al carrito",
	solicitar_cotizacion_especial: "Solicitar cotizacion especial",
	cantidad_a_cotizar: "Cantidad a cotizar",
	cotizar: "Cotizar",
	comprar_ya: "Comprar ya",
	envio_desde: "Envío desde",
	vendido_por: "Vendido por",
	acerca_de_este_producto: "Acerca de este producto",
	sin_descripcion: "Sin descripción",
	colores: "Colores",
	plazo_de_entrega: "Plazo de entrega",
	tiempo: "Tiempo",
	personalizacion: "Personalización",
	materiales: "Materiales (de que esta hecho el producto)",
	protecciones: "Protecciones",
	protege_tus: "Protege tus",
	detalles_del_producto: "Detalles del producto",
	descripcion_general: "Descripción general",
	numero_de_modelo: "Número de modelo",
	muestra: "Muestra",
	condiciones_de_pago: "Condiciones de pago",
	tiempo_de_entrega: "Tiempo de entrega",
	especificaciones: "Especificaciones",
	origen: "Origen",
	material: "Material",
	marca_comercial: "Marca comercial",
	capacidad_de_produccion: "Capacidad de producción",
	uso_especial: "Uso especial",
	detalles_de_embalaje: "Detalles de embalaje",
	codigo_hs: "Código HS",
	descripcion_detallada: "Descripción Detallada",
	//Shop/pages/product/Components/Producto/Resenia.js
	calificacion: "Calificación",
	calificacion_por_estrellas: "Calificación por estrellas",
	de: "de",
	comentarios: "Comentarios",
	reportar: "Reportar",

	/*
	   *
	   * /recuperar
	   */
	//Shop/pages/recuperar/Components/Recuperar/index.js
	te_enviamos_un_correo_de_recuperacion: "Te enviamos un correo de recuperación",
	ingresa_la_nueva_contraseña_para: "Ingresa la nueva contraseña para",
	// /recuperar/token
	link_expirado: "Link expirado",
	/*
	   *
	   * /registro
	   */
	//Shop/pages/registro/Components/Registro/index.js
	ya_tienes_cuenta: "Ya tienes cuenta?",

	/*
	   *
	   * /reviews
	   */
	//Shop/pages/reviews/index.js
	mis_comentarios: "Mis Comentarios",
	evaluar_producto: "Evaluar producto",
	gracias_por_el_comentario: "Gracias por el comentario, ha sido enviado",
	tu_comentario_esta_siendo_procesado: "Tu comentario esta siendo procesado, esto puede tardar. Agradecemos tu paciencia",
	calificacion_general: "Calificación general",
	titulo_del_comentario: "Título del comentario",
	agregar_un_comentario: "Agregar un comentario",

	/**
	  * /search
	  * Shop/pages/search/Components/Search/index.js
	  */
	desea_eliminar_historial_busquedas: "¿Deseas eliminar tu historial de busquedas?",
	busqueda_reciente: "Búsqueda reciente",
	no_se_han_realizado_busquedas: "No se han realizado búsquedas recientes",
	/** * /search/keyword
	  * Shop/pages/search/keyword/Components/ResultSearch/index.js
	  */
	lo_sentimos_no_hay_resultados: "Lo sentimos no se han encontrado resultados, prueba con otra búsqueda",
	producto_destacado: "Producto destacado",

	/**
	  *
	  * Admin
	  *
	  */
	//Admin/components/dashboard.js
	bienvenido_a_tu_panel: "Bienvenido al Panel de tu tienda en linea",
	tu_pagina_es: "Tu página es",
	para_configurar_tu_dominio: "Para configurar el nombre de tu dominio propio es necesario ir a Configuraciones y dar Click en la opción Dominios",
	abrir: "Abrir",
	bienvenido: "Bienvenido",
	saldos: "Saldos",
	resumen_de_saldos: "Resumen de Saldos",
	historial_de_pagos: "Historial de Pagos",
	visitas: "Visitas",
	visitas_diarias: "Visitas Diarias",
	inventario: "Inventario",
	dropshiping: "Dropshiping",
	mi_catalogo: "Mi Catálogo",
	cotizaciones: "Cotizaciones",
	solicitudes_de_cotizacion: "Solicitudes de Cotización",
	configuraciones: "Configuraciónes",
	gestionar_informacion_de_la_empresa: "Gestionar Información de la Empresa",
	configurar_carrusel: "Configurar Carrusel",
	configurar_sitio_web: "Configurar Sitio Web",
	direccion_de_recoleccion: "Dirección de recolección",
	mi_cuenta_bancaria: "Mi cuenta bancaria",
	dominios: "Dominios",
	politicas: "Politicas",
	ayuda: "Ayuda",
	suscripcion: "Suscripción",
	estado_de_la_suscripcion: "Estado de la suscripción",
	importante: "Importante",
	para_acceder_a_todas_las_funciones: "Para acceder a todas las funciones y subir productos, es indispensable completar la información de tu empresa en las secciones CONFIGURACIÓN y GESTIONAR MI INFORMACIÓN.",
	//Admin/components/Ayuda/faq.js
	actualizado: "Actualizado",
	estas_seguro_que_deseas_borrar: "Estas seguro que deseas borrar?",
	eliminado: "Eliminado",
	nuevo_faq: "Nuevo FAQ",
	editar_faq: "Editar FAQ",
	pregunta: "Pregunta",
	respuesta: "Respuesta",
	opciones: "Opciones",
	guardado: "Guardado",
	//Admin/components/Ayuda/glosario.js
	palabra: "Palabra",
	definicion: "Definición",
	glosario: "Glosario",
	editar_del_glosario: "Editar del glosario",
	//Admin/components/Comentarios/Comentarios.js
	evaluaciones_de: "Evaluaciones de",
	seleccionar_calificacion: "Seleccionar calificación",
	estrellas: "Estrellas",
	mostrar_visibles: "Mostrar Visibles",
	borrar_filtros: "Borrar filtros",
	oculto: "Oculto",
	visible: "Visible",
	fecha_de_publicacion: "Fecha de publicación",
	este_comentario_tiene_pendiente_reportes: "Este comentario tiene pendiente uno o más reportes",
	eliminar_comentario: "Eliminar comentario",
	//Admin/components/Comentarios/ComentariosList.js
	evaluaciones: "Evaluaciones",
	ver_producto: "Ver producto",
	//Admin/components/Configuracion/CompanyInformation/index.js
	informacion_basica_de_empresa: "Información básica de la empresa",
	detalles_de_productos: "Detalles de productos",
	informacion_completa_de_empresa: "Información completa de la empresa",
	personas_de_contacto: "Personas de contacto",
	informacion_de_exportacion: "Información de exportación",
	centro_de_certificados: "Centro de certificados",
	//Admin/components/Configuracion/CompanyInformation/step1_CompanyBasicInfo.js
	fabricante: "Fabricante",
	compañia_comercial: "Compañia comercial",
	oficina_de_compras: "Oficina de compras",
	agente: "Agente",
	distribuidor_mayorista: "Distribuidor/Mayorísta",
	ministerio_de_gobierno: "Ministerio de Gobierno/Oficina/Comisión",
	asociacion: "Asociación",
	servicio_comercial_etc: "Servicio comercial (transporte, finanzas, viajes, publicidad, etc.)",
	nombre_de_la_empresa: "Nombre de la empresa",
	licencia_comercial: "Licencia comercial",
	archivo_de_licencia_comercial: "Archivo de Licencia comercial",
	numero: "Número",
	certificado_de_registro_fiscal: "Certificado de registro fiscal",
	archivo_certificado_de_registro_fiscal: "Archivo Certificado de registro fiscal",
	calle_y_numero: "Calle y Número",
	comprobante_de_domicilio: "Comprobante de domicilio",
	direccion_de_fabrica: "Dirección de fábrica",
	//Admin/components/Configuracion/CompanyInformation/step2_productDetails.js
	industria_principal: "Industria principal",
	otros_productos_que_vende: "Otros productos que vende",
	//Admin/components/Configuracion/CompanyInformation/step3_CompanyCompletedInfo.js
	ventajas_de_la_empresa: "Ventajas de la empresa",
	ventajas_de_la_empresa_min_caracteres: "Ventajas de la empresa mínimo caracteres",
	introduccion_de_la_empresa: "Introducción de la empresa",
	introduccion_de_la_empresa_min_catacteres: "Introducción de la empresa mínimo caracteres",
	año_de_registro_de_la_empresa: "Año de registro de la empresa",
	menos_que: "Menos que",
	mas_de: "Más de",
	personas: "Personas",
	url_sitio_web_de_empresa: "URL del sitio web de la empresa",
	propietario_legal: "Propietario legal",
	metros_cuadrados: "Metros cuadrados",
	condiciones_de_entrega_aceptados: "Condiciones de entrega aceptados",
	tipos_de_pago_aceptados: "Tipos de pago aceptados",
	logo_de_la_empresa: "Logo de la empresa",
	logo: "Logo",
	fotos_de_la_empresa: "Fotos de la empresa",
	haz_click_o_arrastra: "Haz click o arrastra",
	//Admin/components/Configuracion/CompanyInformation/step4_ContactPerson.js
	contacto: "Contacto",
	posicion: "Posición",
	//Admin/components/Configuracion/CompanyInformation/step5_ExportInfo.js
	nombre_de_la_empresa_exportadora: "Nombre de la empresa exportadora",
	america_del_norte: "América del Norte",
	sudeste_asiatico: "Sudeste Asiático",
	oriente_medio: "Oriente Medio",
	america_central: "América Central",
	asia_del_sur: "Asia del Sur",
	america_del_sur: "América del Sur",
	africa: "África",
	asia_oriental: "Asia Oriental",
	europa_del_norte: "Europa del Norte",
	europa_del_este: "Europa del Este",
	oceania: "Oceanía",
	europa_occidental: "Europa Occidental",
	europa_del_sur: "Europa del Sur",
	//Admin/components/Configuracion/CompanyInformation/step6_Certificates.js
	certificado_nuevo: "Certificado nuevo",
	nombre_del_certificado: "Nombre del certificado",
	foto_del_certificado: "Foto del certificado",
	foto: "Foto",
	//Admin/components/Configuracion/Recolecciones/index.js
	horario_fuera_de_rango: "Horario fuera de rango",
	seleccionar_dias: "Seleccionar días",
	lunes: "Lunes",
	martes: "Martes",
	miercoles: "Miercoles",
	jueves: "Jueves",
	viernes: "Viernes",
	sabado: "Sábado",
	selecciona_un_dia_de_la_semana: "Selecciona un día de la semana",
	dias_seleccionados: "Días seleccionados",
	nombre_del_contacto: "Nombre del contacto",
	telefono_del_contacto: "Teléfono del contacto",
	nombre_de_la_empresa_lugar: "Nombre de la empresa o lugar",
	//Admin/components/Configuracion/Website/carrusel/Carrusel.js
	datos_incompletos: "Datos incompletos",
	carrusel_de_imagenes: "Carrusel de imágenes",
	debes_tener_al_menos_una_imagen_en_carrusel: "Debes tener al menos una imagen disponible para el carrusel",
	tamaño_recomendado: "Tamaño recomendado",
	fotos: "Fotos",
	arrastre_en_orden_de_visualizacion: "Arrastre en orden que desee visualizar en la página principal",
	todos_los_cambios_se_reflejaran_al_presionar_guardar: "Todos los cambios se veran reflejados al presionar el botón guardar",
	//Admin/components/Configuracion/Website/carrusel/SliderImg.js
	boton: "Botón",
	titulo_del_boton: "Título del botón",
	limite_de_caracteres: "Limite de caracteres",
	url_del_boton: "URL del botón",
	color_textos: "Color textos",
	titulo: "Título",
	//Admin/components/Configuracion/Website/websiteconfigs/index.js
	url_de_red_social_incorrecta: "URL de red social incorrecta",
	configuraciones_de_tu_tienda: "Configuraciones de tu tienda",
	sitio_web: "Sitio Web",
	activar_sitio_web_y_mostrarlo_online_desactivar: "Activar sitio web y mostrarlo online, desactivar para mostrar 'sitio en construcción'",
	activado: "Activado",
	desactivado: "Desactivado",
	datos_de_tu_ecommerce: "Datos de tu ecommerce",
	idioma: "Idioma",
	nombre_de_tu_tienda: "Nombre de tu tienda",
	telefono_de_atencion: "Teléfono de atención",
	correo_de_atencion: "Correo de atención",
	nombre_menu_principal_ejem: "Nombre de menú principal(Ej.Departamentos)",
	el_nombre_del_menu_principal_aparece_en_nav: "el nombre del menú principal aparece en la barra de navegación principal",
	seccion_quienes_somos: "Sección quienes somos",
	imagenes: "Imágenes",
	para_mejor_experiencia_usa_png_sin_fondo: "Para mejor experiencia usa PNG sin fondo",
	iconos_de_carrito: "Iconos de carrito",
	iconos_de_verificacion: "Iconos de verificacion",
	barra_de_navegacion: "Barra de navegación",
	color_texto_barra_de_navegacion: "Color de texto barra de navegación",
	color_principal: "Color principal",
	color_secundario: "Color secundario",
	color_textos_informativos: "Color textos informativos",
	color_de_resaltado: "Color de resaltado",
	color_de_fondo_pie_pagina: "Color de fondo del pie de página",
	color_de_texto_pie_pagina: "Color de texto del pie de página",
	dia_inicio: "Día inicio",
	dia_fin: "Día fin",
	hora_inicio: "Hora inicio",
	hora_fin: "Hora fin",
	imagenes_publicitarias: "Imágenes publicitarias",
	imagen: "Imágen",
	//Admin/components/Cotizaciones/cotizacion.js
	cotizacion: "Cotización",
	fecha_solicitud: "Fecha solicitud",
	informacion: "Información",
	pais_destino: "País destino",
	ciudad_destino: "Ciudad destino",
	//Admin/components/Cotizaciones/index.js
	codigo_del_producto: "Código del producto",
	fecha: "Fecha",
	//Admin/components/Inventarios/productos/edit.js y new.js
	seguro_que_deseas_archivar: "Estas seguro que deseas archivar?",
	actualizar_producto: "Actualizar producto",
	industria: "Industria",
	departamento: "Departamento",
	categoria: "Categoría",
	nombre_del_producto: "Nombre del producto",
	descripcion_breve: "Descripción breve",
	uso: "Uso",
	modelo: "Modelo",
	los_modelos_son_generados_por_el_sistema: "Los modelos son generados por el sistema",
	palabras_clave: "Palabras clave para facilitar la búsqueda del producto (ej: Negro, Invierno, Botas)",
	atributos: "Atributos",
	sin_atributo: "Sin Atributo",
	tamaño: "Tamaño",
	ancho: "Ancho",
	capacidad: "Capacidad",
	tipo: "Tipo",
	color: "Color",
	sub_atributos_para: "Sub atributos para",
	codigo_de_barras: "Código de barras",
	registrar: "Registrar",
	completar_datos_para: "Completar datos para",
	colores: "Colores",
	tamaño_de_pieza: "Tamaño de Pieza (Cuanto mide la Prenda o accesorio)",
	largo_de_la_pieza: "Largo de la pieza",
	ancho_de_la_pieza: "Ancho de la pieza",
	alto_de_la_pieza: "Alto de la pieza",
	peso_de_la_pieza: "Peso de la pieza",
	caracteristicas: "Características",
	datos_tecnicos: "Datos técnicos",
	fotos_del_producto: "Fotos del producto",
	el_tamaño_debe_ser_menor_5_mb: "El tamaño del archivo de imagen debe ser inferior a 5 MB",
	formatos_admitidos: "Formatos admitidos",
	el_tamaño_recomendado_es: "El tamaño de imagen recomendado es superior a",
	las_imagenes_deben_ser_claras: "Las imágenes deben ser claras y fáciles de ver para los compradores de un vistazo",
	añadir_una_marca_a_tu_imagen_puede_evitar_robos: "Agregar un escudo a tu imagen puede evitar robos",
	imagenes_de_fotos_principales: "Imágenes de fotos principales utilizadas en la búsqueda. La imagen debe tener más de 350 x 350 píxeles. La proporción de tamaño de la foto debe oscilar entre 3:4 y 4:3",
	foto_portada: "Foto Portada",
	informacion_de_embalaje: "Información de embalaje",
	caja_de_carton: "Caja de cartón",
	bolsa: "Bolsa",
	costal: "Costal",
	tipo_de_embalaje: "Tipo de embalaje",
	foto_del_embalaje: "Foto del embalaje",
	piezas_por_embalaje: "Piezas por embalaje",
	numero_entero: "Número entero",
	embalaje_interno: "Embalaje interno",
	largo: "Largo",
	numero_decimal: "Número decimal",
	ancho: "Ancho",
	alto: "Alto",
	metro_cubico: "Metro cúbico",
	peso_bruto: "Peso bruto",
	peso_neto: "Peso neto",
	informacion_de_compra: "Información de compra",
	plazo_de_entrega_precio: "Plazo de entrega precio",
	unidad_de_venta: "Unidad de venta",
	cantidad_minima_de_pedido: "Cantidad mínima de pedido",
	rango_minimo: "Rango mínimo",
	rango_maximo: "Rango maximo",
	plazo_de_entrega_minimo: "Plazo de entrega mínimo",
	plazo_de_entrega_maximo: "Plazo de entrega maximo",
	unidad: "Unidad",
	muestra: "Muestra",
	precio_de_muestra: "Precio de muestra",
	opciones_de_pago: "Opciones de pago",
	protecciones: "Protecciones",
	personalizar: "Personalizar",
	capacidad_mensual: "Capacidad mensual",
	contenedores: "Contenedores",
	informacion_de_recoleccion: "Información de recolección",
	archivar: "Archivar",
	el_campo_uso_es_obligatorio: "El campo uso es obligatorio",
	el_campo_keywords_es_obligatorio: "El campo keywords es obligatorio",
	//Admin/components/Inventarios/catalogo.js
	añadir_stock: "Añadir Stock",
	producto_restaurado: "Producto Restaurado",
	fecha_de_la_orden: "Fecha de la orden",
	folio_de_la_orden: "Fecha de la orden",
	nuevo_producto: "Nuevo Producto",
	catalogo: "Catálogo",
	archivados: "Archivados",
	productos_sin_departamento: "Productos sin departamento",
	historial_de_ordenes: "Historial de ordenes",
	visible_en_mi_tienda: "Visible en mi tienda",
	variante: "Variante",
	agotado: "Agotado",
	//Admin/components/Pedidos/listaProductos.js
	total_pagado_sin_costo_envio: "Total pagado (sin costo de envío)",
	cupon_activo: "Cupón activo",
	nombre_del_cupon: "Nombre del cupón",
	descuento: "Descuento",
	//Admin/components/Pedidos/orderStatus.js
	progreso: "Progreso",
	estado: "Estado",
	usuario_responsable: "Usuario responsable",
	piezas_por_display: "Piezas por display",
	piezas_entregadas: "Piezas entregadas",
	piezas_en_carrito: "Piezas en carrito",
	cancelar_orden: "Cancelar orden",
	tienda: "Tienda",
	colaborador: "Colaborador",
	no_ha_sido_tomado: "No ha sido tomado",
	ver_estado: "Ver estado",
	//Admin/components/Policies/pagosEnvios.js
	las_politicas_de_pagos_envios_se_han_actualizado: "Las políticas para pagos y envios se han guardado satisfactoriamente",
	pagos_y_envios: "Pagos y Envios",
	las_politicas_de_compra_se_han_actualizado: "Las políticas de compra se han guardado satisfactoriamente",
	politica_de_compra: "Política de compras",
	//Admin/components/Wizard/progressNav/ProgressNav.js
	configuracion_de_la_tienda: "Configuración de la tienda",
	imagenes_de_carrusel: "Imágenes de carrusel",
	configurar_redes_sociales: "Configurar redes sociales",
	//Admin/components/Wizard/steps/StepFive.js
	seleccione_icono_y_url_facebook: "Seleccione icono y url de facebook",
	verificar_url_facebook: "Verificar url de facebook debe tener https://",
	seleccione_icono_y_url_instagram: "Seleccione icono y url de instagram",
	verificar_url_instagram: "Verificar url de instagram debe tener https://",
	seleccione_icono_y_url_youtube: "Seleccione icono y url de youtube",
	verificar_url_youtube: "Verificar url de youtube debe tener https://",
	todos_los_cambios_se_reflejaran_al_presionar_el_boton: "Todo sus cambios o ajustes se verán reflejados al presionar el botón de Finalizar en la parte inferior",
	estas_redes_se_añadiran_al_ecommerce: "Estas redes sociales serán agregadas al sitio web para su despliegue en el e-commerce",
	verifica_http_previamente: "Verifica que previamente cada url incluya http://",
	con_cuantas_redes_sociales_cuentas: "¿Con cuáles redes sociales cuentas?",
	finalizar: "Finalizar",
	anterior: "Anterior",
	//Admin/components/Wizard/steps/StepOne.js
	porfavor_completa_color_barra_navegacion: "Por favor completa el campo color barra de navegación.",
	porfavor_completa_color_texto_barra_navegacion: "Por favor completa el campo color texto barra de navegación.",
	porfavor_completa_color_principal: "Por favor completa el campo color principal.",
	porfavor_completa_color_secundario: "Por favor completa el campo color secundario",
	porfavor_completa_color_textos_informativos: "Por favor completa el campo de color para textos informativos",
	porfavor_completa_color_resaltado: "Por favor completa el campo color de resaltado",
	porfavor_completa_color_footer: "Por favor completa de fondo del pie de página",
	porfavor_completa_color_footer_text: "Por favor completa el campo texto del pie de página",
	todos_los_cambios_se_reflejaran_al_presionar_siguiente: "Todo sus cambios o ajustes se verán reflejados al presionar el botón de SIGUIENTE en la parte inferior",
	selecciona_un_idioma: "Selecciona un idioma",
	sube_el_logo_de_tu_tienda: "Sube el logo de tu tienda",
	siguiente: "Siguiente",
	//Admin/components/Wizard/steps/StepTwo.js
	no_olvides_subir_imagen: "No olvides subir una imagen antes de continuar.",
	sube_una_imagen_para_tu_sitio_la_cual_se_utilizara_como_banner_principal: "Sube una imagen para tu sitio la cual se utilizara como imagen principal/banner en la sección principal",
	un_banner_es: "Un banner es una imagen informativa/publicitaria que se le puede agregar texto y un botón que lleva al cliente a un link específico dentro o fuera de tu e-commerce, se localiza en la página principal de tu e-commerce hasta arriba. Se requiere configurar al menos uno.",
	imagenes_con_dimensiones_panoramicas: "Imágenes con dimensiones panorámicas",
	//Admin/components/Wizard/index.js
	todo_puede_ser_modificado_despues: "Todo puede ser modificado más adelante.",
	esto_es_la_configuracion_inicial: "Esto sólo es la configuración inicial de tu tienda.",
	//Admin/pages/balance/payment-history/index.js
	monto: "Monto",
	cuenta: "Cuenta",
	referencia: "Referencia",
	mes: "Mes",
	//Admin/pages/balance/summary/index.js
	totales: "Totales",
	saldo_total: "Saldo Total",
	pedidos_totales: "Pedidos Totales",
	saldo_listo: "Saldo Listo",
	pedidos_entregados: "Pedidos Entregados",
	saldo_pendiente: "Saldo Pendiente",
	pedidos_pendientes: "Pedidos Pendientes",
	//Admin/pages/catalogoglobal/index.js
	tendras_que_volver_agregar_datos_de_precios: "Tendrás que volver a agregar tus datos así como tus precios, si deseas volver a importar",
	catalogo_global: "Catálogo Global",
	proveedor_vendedor: "Proveedor/Vendedor",
	en_mi_tienda: "En mi tienda",
	//Admin/pages/domains/index.js
	video_tutorial_de_dominio_personalizado: "Videotutorial de dominio personalizado",
	instrucciones_para_activar_dominio: "Instrucciones para activar tu dominio",
	agrega_los_registros_dns_a_tu_dominio: "Agregue los siguientes registros DNS a su dominio",
	espera_la_propagacion_dns: "Espere la propagación de DNS durante aproximadamente 15 minutos",
	mis_dominios: "Mis Dominios",
	aqui_podras_gestionar_tus_dominios: "Aquí podrás gestionar tus dominios vinculados a tu tienda",
	video_tutorial: "Video tutorial",
	nuevo_dominio: "Nuevo Dominio",
	añadir_nuevo_dominio: "Añadir nuevo dominio",
	que_dominio_quieres_agregar: "¿Qué dominio quieres agregar a tu cuenta?",
	dominio: "Dominio",
	certificado: "Certificado",
	activar: "Activar",
	activo: "Activo",
	validacion_pendiente: "Validación pendiente",
	genera_y_instala_certificado_ssl: "Generar y instalar SSL/Certificado",
	//Admin/pages/dropshiping/index.js
	error_en_precio: "Error en precio",
	cantidad_minima_de_pedido_para: "Cantidad mínima de pedido para",
	precio_original: "Precio Original",
	mi_precio: "Mi precio",
	mi_dropshiping: "Mi Dropshiping",
	precios: "Precios",
	//Admin/pages/entrega/index.js
	entregar: "Entregar",
	nombre_quien_recoge: "Nombre de quién recoge",
	firma: "Firma",
	volver_a_intentar: "Volver a intentar",
	confirmar: "Confirmar",
	//Admin/pages/mi-cuenta-bancaria/index.js
	mi_cuenta_bancaria: "Mi cuenta bancaria",
	banco: "Banco",
	numero_de_cuenta: "Número de cuenta",
	numero_de_tarjeta: "Número de tarjeta",
	//Admin/pages/suscripcion/plans/premium/index.js
	conekta_locale: "es",
	//Admin/pages/suscripcion/plans/index.js
	planes: "Planes",
	elige_tu_paquete: "Elige tu paquete",
	catalogo_ilimitado_de_productos: "Catálogo ilimitado de productos",
	acceso_completo_ilimitado_de_productos: "Acceso completo limitada de productos o categorías",
	envio_fedex: "Envío Fedex",
	soporte_basico_por_correo: "Soporte básico por correo electrónico o chat",
	dominio_personalizado: "Dominio personalizado",
	ssl_certificado_gratis: "SSL/Certificado gratis",
	soporte_personalizado: "Soporte personalizado",
	mensual: "Mensual",
	anual: "Anual",
	//Admin/pages/suscripcion/index.js
	mi_suscripcion: "Mi Suscripción",
	no_tienes_una_suscripcion: "No tienes una suscripción activa",
	reactiva_o_obten_suscripcion: "Reactiva o obten una suscripción",
	siguiente_pago: "Siguiente pago",
	plan: "Plan",
	metodo: "Metodo",
	tarjeta: "Tarjeta",
	tarjeta_exp: "Exp",
	//Admin/pages/ventas/index.js
	sin_pedidos: "Sin pedidos",
	pedido_id: "Pedido id",
	cliente: "Cliente",
	expedidor: "Expedidor",
	vendedor: "Vendedor",
	informacion: "Información",
	listo_para_enviar: "Listo para enviar",
	imprimir_guia: "Imprimir guía",
};

export default esTranslations;
