import { lazy } from 'react';

export const DropShipingPageIndex = lazy(() => import('./dropshiping'));
export const CatalogoGlobalPageIndex = lazy(() => import('./catalogoglobal'));
export const VentasPageIndex = lazy(() => import('./ventas'));
export const EntregaPageIndex = lazy(() => import('./entrega'));
export const BalanceSummaryPageIndex = lazy(() => import('./balance/summary'));
export const PaymentHistoryPageIndex = lazy(() => import('./balance/payment-history'));
export const MiBanca = lazy(() => import('./mi-cuenta-bancaria'));
export const Domains = lazy(() => import('./domains'));
export const SuscripcionPageIndex = lazy(() => import('./suscripcion'));
export const PlansPageIndex = lazy(() => import('./suscripcion/plans'));
export const PremiumPageIndex = lazy(() => import('./suscripcion/plans/premium'));
export { Login, Create } from './login';
export const PerfilEmpresa = lazy(() => import('./mis-datos'));
export const Mensajes = lazy(() => import('./mensajes'));
export const Carrusel = lazy(() => import('./mi-tienda'));
export const Cotizaciones = lazy(() => import('./cotizaciones'));
export const Cotizacion = lazy(() => import('./cotizaciones/cotizacion'));
