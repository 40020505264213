// Define el límite de tiempo (en milisegundos)
const REQUEST_THROTTLE_LIMIT = 3000; // 3 segundo

// Máximo de solicitudes inmediatas permitidas antes de aplicar el throttle
const MAX_REQUESTS_BEFORE_THROTTLE = 3;

// Almacena el tiempo de la última solicitud y la cantidad de solicitudes por URL
const requestTimestamps = new Map();
const requestCounts = new Map();

// metodos post que se usan en useEffect es decir que no se pueden bloquear a parte de los metodos get que tambien no se deben bloquear
const urlExceptionThrottle = [
	'/api/proveedor/company_profile',
	'/api/proveedor/webconfigs',
	'/api/reseller/post_categoria_departamento',
	'/api/visto',
	'/api/reseller/producto/search',
	'/api/productos',
	'/api/oxxopay',
	'/api/pedido',
	'/api/reseller/packaging/rate-service',
	'/api/reseller/aboutus',
	'/api/getVistos',
]
/**
 * Función throttle para controlar la frecuencia de solicitudes
 */
const throttle = (config) => {
  return new Promise((resolve, reject) => {
    const currentTime = Date.now();
    const url = config.url;

    // Si la solicitud es GET o está en la lista de excepciones
    /**
      * el codigo que se ejecuta en el if es para permitir las solicitudes get y las excepciones
      * un usuario puede ejecutar varias solicitudes get sin ser bloqueado hasta maximo 3(MAX_REQUESTS_BEFORE_THROTTLE) solicitudes simultaneas a una misma url
      * despues de 3 solicitudes simultaneas a una misma url se bloquea la solicitud por 3 segundos
      * siempre y cuando sea get o este en la lista urlExceptionThrottle
      */
    if (config.method === 'get' || urlExceptionThrottle.includes(url)) {
      if (!requestCounts.has(url)) {
        requestCounts.set(url, 0);
        requestTimestamps.set(url, currentTime);
      }

      let requestCount = requestCounts.get(url);
      let lastRequestTime = requestTimestamps.get(url);

      if (requestCount < MAX_REQUESTS_BEFORE_THROTTLE) {
        // Permitir la solicitud inmediatamente si está dentro del límite de 5
        requestCounts.set(url, requestCount + 1);
        resolve(config);
      } else if (currentTime - lastRequestTime >= REQUEST_THROTTLE_LIMIT) {
        // Si han pasado 3 segundos, reseteamos el contador y permitimos la solicitud
        requestCounts.set(url, 1);
        requestTimestamps.set(url, currentTime);
        resolve(config);
      } else {
        // Si ya pasó el límite de 5 y no han pasado los 3 segundos, bloqueamos la solicitud
        reject('Demasiadas solicitudes en poco tiempo. Inténtalo de nuevo en unos instantes.');
      }
    }
    /**
      * de lo contrario solo se permite 1 solicitud cada 3 segundos
      */
    else {
      // Para las demás solicitudes que no son GET ni excepciones
      let lastRequestTime = requestTimestamps.get('global') || 0;

      if (currentTime - lastRequestTime >= REQUEST_THROTTLE_LIMIT) {
        requestTimestamps.set('global', currentTime);
        resolve(config);
      } else {
        reject('Demasiadas solicitudes en poco tiempo. Inténtalo de nuevo en unos instantes.');
      }
    }
  });
};

export default throttle;
