import React from 'react';

//components
export const Dashboard = React.lazy(() => import('./dashboard'));
export const Visitas = React.lazy(() => import('./Visitas'));
//modules
export { NewProduct, EditProduct, Catalogo } from './Inventarios';
export { OrderStatus, ListaProductos } from './Pedidos';
export { WebConfigs, AvisoPrivacidad, Pickups } from './Configuracion';
export { Faq, Glosario } from './Ayuda';
export { PoliticaCompra, PagosEnvios } from './Policies';
export { ComentariosList, Comentarios } from './Comentarios';
