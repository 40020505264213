import axios from 'axios';
import throttle from './throttle';

const instance = axios.create({
	baseURL: 'https://kangoru-shop-api.asiamart.com.mx',
})

/**
  * urls que pueden ser solicitadas y no redirigir
  */
const urlException = [
        '/api/user',
        //'/api/misdirecciones', //se deshabilito por ticket, un usurio no puede comprar si no esta logueado
]
 
/**
 * Interceptor para los requests, añadiendo el throttling
 */
instance.interceptors.request.use(
  (config) => {
    // Llamamos a throttle, si la solicitud puede proceder, retornamos la config
    return throttle(config)
      .then((resolvedConfig) => resolvedConfig)  // Devuelve la config si pasa el throttle
      .catch((error) => Promise.reject(error));  // Si está bloqueada, rechaza la solicitud
  },
  (error) => Promise.reject(error)  // Manejo de errores
);


/**
  * Interceptor para los responses
  */
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (401 === error.response?.status && !urlException.includes(error.response?.config.url)) {
            window.location.replace('/login');
           //createBrowserHistory().push('/login');
           //window.location.reload();
        }
        if(error.response?.status===404){
            window.location.replace('/404');
           //createBrowserHistory().push('/404');
           //window.location.reload();
        }

        if(error.response?.status===402 && !window.location.href.includes('account_suspended')){
            window.location.replace('/account_suspended');
        }

        return Promise.reject(error);
    });

if (typeof window !== 'undefined') {
        instance.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
        instance.defaults.headers.common['X-Frontend-URL'] = window.location.href;
        instance.defaults.headers.common['X-Frontend-Domain'] = window.location.hostname.replace('www.','');
}

export default instance;
